const DateTime = require('luxon').DateTime;
const weekDaysGen = require('../utils/weekDays');

module.exports = function(events, startDate) {
  let weekDays = weekDaysGen(startDate);
  let result = [];

  weekDays.forEach(day => {
    let entries = events
      .filter(e => e.entry || e.entries)
      .filter(e => DateTime.fromISO(e.startDate) <= day && day <= DateTime.fromISO(e.endDate || e.startDate))
      .filter(e => !e.weekDayExceptions || e.weekDayExceptions.indexOf(day.weekday) === -1)
      .map(event => {
        let entry = undefined;

        if (event.entries) {
          let date = day.toISODate();
          entry = event.entries.find(e => e.date === date);
        } else if (event.entry) {
          entry = event.entry;
        }

        if (!entry) return undefined;
        entry = JSON.parse(JSON.stringify(entry));
        entry.date = day.toISODate();
        entry.id = event.id + '-' + entry.date;
        if (!entry.note) {
          entry.note = event.note;
        }
        entry.generated = true;
        if (event.accepted) {
          entry.accepted = true;
        }
        return entry;
      })
      .filter(e => e);
    result = result.concat(entries);
  });

  return result;
};
