const computeSumsByIcon = require('../../common/computeSumsByIcon');

module.exports = function (entries) {
  let totals = computeSumsByIcon(entries);
  if (entries.every((e) => e.generated)) {
    totals.push({
      icon: 'magic',
      color: 'black',
      tooltip: 'updated',
      tooltip18: {
        en: 'Entries will be further updated',
        fr: 'Les entrées seront mises à jour'
      },
    });
  } else if (entries.some((e) => e.generated)) {
    totals.push({
      icon: 'magic',
      color: 'warning',
      tooltip: 'update-freeze',
      tooltip18: {
        en: 'Updates stopped',
        fr: 'Mise à jour arrêtée'
      }
    });
  }

  if (entries.some((e) => !e.valid)) {
    totals.push({
      icon: 'exclamation-triangle',
      color: 'danger'
    });
  }
  return totals;
};