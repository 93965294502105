const { DateTime, Duration, Interval } = require('luxon');
const zipWithNext = require('../../utils/zipWithNext');
const summaryCreate = require('../../common/summaryCreate');
const { groupBy } = require('ramda');

module.exports = function(sheet, previous) {
  let notices = [];

  let deductions = [];

  const entries = Object.values(sheet.timeEntriesById);
  const byDay = groupBy(e => e.date, entries);

  Object.entries(byDay).forEach(([date, dayEntries]) => {
    const allWorkorPauseEntries = dayEntries.every(
      e =>
        (e.op === 'work' && e.valid && e.input === 'from-until') ||
        (e.op === 'pause' && e.valid && e.input === 'duration')
    );
    if (!allWorkorPauseEntries) return;

    const dayTotals = (sheet.totalsByDay && sheet.totalsByDay[date]) || [];
    const work = dayTotals.find(t => t.icon === 'building');
    if (!work || !work.duration) return;

    const pause = dayTotals.find(t => t.icon === 'coffee');
    const pauseMins = (pause && pause.duration && Duration.fromISO(pause.duration).as('minutes')) || 0;

    let pauseRequiredMin = 0;
    let workMin = Duration.fromISO(work.duration).as('minutes');
    if (work.negative) workMin = -workMin;

    if (workMin >= 540) {
      // work > 9 hours -> 1 hour of pause
      pauseRequiredMin = 60;
    } else if (workMin >= 420) {
      // work > 7 hours -> 30 minutes of pause
      pauseRequiredMin = 30;
    } else if (workMin >= 330) {
      // work > 5.5 hours -> 15 minutes of pause
      pauseRequiredMin = 15;
    }

    if (pauseRequiredMin === 0) return previous;

    // entries must be sorted
    const entries = [...dayEntries].filter(e => e.op === 'work').sort((a, b) => a.from.localeCompare(b.from));

    // distance between the work entries
    const betweenMins = zipWithNext(entries, (a, b) => {
      let aUntil = DateTime.fromISO(a.date + 'T' + a.until);
      let bFrom = DateTime.fromISO(b.date + 'T' + b.from);
      let interval = Interval.fromDateTimes(aUntil, bFrom);
      return interval.length('minutes');
    });

    const pauseTotalMin = betweenMins.reduce((tot, curr) => tot + curr, pauseMins);

    if (pauseTotalMin < pauseRequiredMin) {
      const diff = pauseRequiredMin - pauseTotalMin;
      const dt = DateTime.fromISO(date);
      notices.push({
        id: 'ltr15-' + date,
        color: 'warning',
        icon: 'exclamation-circle',
        date: date,
        message: 'notice_ltr15',
        message18: {
          en: `${dt
            .setLocale('en')
            .toFormat(
              'cccc'
            )}: ${pauseRequiredMin} minutes of break required, only ${pauseTotalMin} taken, ${diff} minutes deduction.`,
          fr: `${dt
            .setLocale('fr')
            .toFormat(
              'cccc'
            )} : ${pauseRequiredMin} minutes de pause requises, ${pauseTotalMin} minutes prises, déduction de ${diff} minutes.`,
          de: `${dt
            .setLocale('fr')
            .toFormat(
              'cccc'
            )} : ${pauseRequiredMin} Minuten Pause erforderlich, nur ${pauseTotalMin} genommen, ${diff} Minuten Abzug.`
        }
      });
      deductions.push(diff + pauseMins);
    }
  });


  if (notices.length > 0) {
    let total = deductions.reduce((a, b) => a + b, 0);
    let pause = summaryCreate('pause', Duration.fromObject({ minutes: total }));
    return {
      totals: previous.totals,
      notices: [...previous.notices, ...notices],
      summaries: [...previous.summaries.filter(s => s.icon !== 'coffee'), pause]
    };
  } else {
    return previous;
  }
};
