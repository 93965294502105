<template>
  <div>
    <div class="text-sm text-gray-500" v-show="!online">
      {{ $t('update_progress_offline') }}
    </div>
    <sqr-progress v-show="online && loading" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SqrProgress from '@/sqrd/SqrProgress';
export default {
  name: 'UpdateProgress',
  components: { SqrProgress },
  props: { loading: Boolean },
  computed: {
    ...mapState('online', ['online']),
  },
};
</script>
