module.exports = function (sheet, previous, error) {
  return {
    stop: true,
    totals: [],
    notices: [
      {
        id: 'error',
        color: 'danger',
        icon: 'exclamation-triangle',
        message: 'notice_error',
        message18: {
          en: 'error while computing the work week',
          fr: 'erreur lors du calcul de la semaine de travail',
          de: 'Fehler bei der Berechnung der Arbeitswoche'
        },
        error
      }
    ],
    summaries: []
  };
};