const { DateTime, Duration, Interval } = require('luxon');
const zipWithNext = require('../../utils/zipWithNext');
const summaryCreate = require('../../common/summaryCreate');
const {groupBy} = require('ramda');

module.exports = function (sheet, previous) {

  let notices = [];

  let deductions = [];

  const entries = Object.values(sheet.timeEntriesById);
  const byDay = groupBy(e => e.date, entries);

  Object.entries(byDay).forEach(([date, dayEntries]) => {
    const allWorkEntries = dayEntries.every((e) => e.op === 'work' && e.valid && e.input === 'from-until');
    if (!allWorkEntries) return;

    const work = sheet.totalsByDay &&
      sheet.totalsByDay[date] &&
      sheet.totalsByDay[date].find(t => t.icon === 'building');

    if (!work || !work.duration) return;

    let pauseRequiredMin = 0;
    let workMin = Duration.fromISO(work.duration).as('minutes');
    if (work.negative) workMin = -workMin;

    // if (workMin >= 540) {
    //   // work > 9 hours -> 1 hour of pause
    //   pauseRequiredMin = 60;
    // } else if (workMin >= 420) {
    //   // work > 7 hours -> 30 minutes of pause
    //   pauseRequiredMin = 30;
    // } else if (workMin >= 330) {
    //   // work > 5.5 hours -> 15 minutes of pause
    //   pauseRequiredMin = 15;
    // }

    if (workMin >= 570) {
      // work > 9 hours 30 minutes -> 1 hour of pause
      pauseRequiredMin = 60;
    } else if (workMin >= 450) {
      // work > 7 hours 30 minutes -> 30 minutes of pause
      pauseRequiredMin = 30;
    } else if (workMin >= 345) {
      // work > 5 hours 45 minutes -> 15 minutes of pause
      pauseRequiredMin = 15;
    }

    if (pauseRequiredMin === 0) return; // work < 5.5 hours ignore

    // entries must be sorted
    let entries = [...dayEntries].sort((a, b) => a.from.localeCompare(b.from));

    // distance between the work entries
    let betweenMins = zipWithNext(entries, (a, b) => {
      let aUntil = DateTime.fromISO(a.date + 'T' + a.until);
      let bFrom = DateTime.fromISO(b.date + 'T' + b.from);
      let interval = Interval.fromDateTimes(aUntil, bFrom);
      return interval.length('minutes');
    });

    let pauseTotalMin = betweenMins.reduce((tot, curr) => tot + curr, 0);

    if (pauseTotalMin < pauseRequiredMin) {
      let diff = pauseRequiredMin - pauseTotalMin;
      let dt = DateTime.fromISO(date);
      notices.push({
        id: 'ltr15webcor-' + date,
        color: 'warning',
        icon: 'exclamation-circle',
        date: date,
        message: 'notice_ltr15',
        message18: {
          en: `${dt.setLocale('en').toFormat('cccc')}: ${pauseRequiredMin} minutes of break required, only ${pauseTotalMin} taken, ${diff} minutes deduction.`,
          fr: `${dt.setLocale('fr').toFormat('cccc')} : ${pauseRequiredMin} minutes de pause requises, ${pauseTotalMin} minutes prises, déduction de ${diff} minutes.`,
          de: `${dt.setLocale('fr').toFormat('cccc')} : ${pauseRequiredMin} Minuten Pause erforderlich, nur ${pauseTotalMin} genommen, ${diff} Minuten Abzug.`
        }
      });
      deductions.push(diff);
    }
  });

  if (notices.length) {
    let total = deductions.reduce((a, b) => a + b, 0);
    let pause = summaryCreate('pause', Duration.fromObject({minutes: total}));
    return {
      totals: previous.totals,
      notices: [...previous.notices, ...notices],
      summaries: [...previous.summaries, pause],
    };
  } else {
    return previous;
  }
};