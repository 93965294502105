const DateTime = require('luxon').DateTime;

module.exports = function (date) {
  let weekStart = DateTime.fromISO(date).startOf('week');
  let weekEnd = weekStart.endOf('week');
  let weekDays = [];
  let weekDay = weekStart;
  while (weekDay <= weekEnd) {
    weekDays.push(weekDay);
    weekDay = weekDay.plus({day: 1});
  }
  return weekDays;
};