<template>
  <main>
    <sqr-progress v-show="cLoading || aLoading || loading" />
    <section class="hero is-light is-small is-bold">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-10-desktop">
              <div class="control" v-if="sheetNext">
                <sqr-router-link
                  icon="arrow-up"
                  :label-raw="sheetNext.startDate | week"
                  :to="{ sheet: 'sheet', params: sheetNext.params }"
                  is-link
                  class="has-text-grey-dark is-size-6"
                />
              </div>
              <div>
                <h1 class="title">
                  <span>{{ sid | week }} </span>
                  <span>{{ $t('sheet_title') }} </span>
                </h1>
                <h2 class="subtitle mb-0 is-hidden-mobile">
                  {{ $t('sheet_subtitle') }}
                </h2>
              </div>
              <div class="control" v-if="sheetPrevious">
                <sqr-router-link
                  icon="arrow-down"
                  :label-raw="sheetPrevious.startDate | week"
                  :to="{ sheet: 'sheet', params: sheetPrevious.params }"
                  is-link
                  class="has-text-grey-dark is-size-6"
                />
              </div>
            </div>
            <slot></slot>
          </div>
        </div>
      </div>
    </section>

    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="isManager">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs" v-if="company && account">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_accounts') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'account', params: { cid, aid } }"><a><account-name :account="account"/></a></router-link>
            <router-link tag="li" :to="{ name: 'sheets', params: { cid, aid } }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_account_sheets') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'sheets-year', params: { cid, aid, year } }" class="is-hidden-mobile"><a>{{ year }}</a></router-link>
            <router-link tag="li" :to="{ name: 'sheet', params: { cid, aid } }"><a>{{ week }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>

    <sqr-error :error="loadError" />

    <section class="section" v-if="!exists && !loading && !loadError">
      <div class="container">
        <div class="columns">
          <div class="column is-10-desktop">
            <sqr-button
              icon="plus"
              label="account_sheet_create"
              color="primary"
              size="large"
              @click="create()"
              :is-loading="saving"
            />
            <sqr-error-note :error="saveError" />
          </div>
        </div>
      </div>
    </section>

    <section class="px-0 py-0 md:py-4 lg:py-8" v-if="sheet && !loadError">
      <div class="container">
        <div class="columns is-desktop">
          <div class="column is-8-tablet is-6-desktop">
            <status-message
              :record="sheet"
              @click="update({ updates: { status: 'pending' } })"
            />
            <week-days
              v-if="sheet"
              :sheet="sheet"
              :disabled="disabled"
              @add="entryAdd"
              @change="entryChange"
              @remove="entryRemove"
            />
            <week-notices v-if="sheet" :notices="sheet.notices" />
            <week-total :sheet="sheet" />
          </div>
          <div class="column is-8-tablet is-6-desktop is-5-widescreen">
            <week-settings
              class="field"
              :sheet="sheet"
              @change="fieldSetAndCompute({ field: 'settings', value: $event })"
              :disabled="disabled"
            />
            <div class="space px-4 md:px-0" v-if="sheet">
              <div class="field" v-if="isManager">
                <status-buttons
                  size="medium"
                  :status="sheet.status"
                  :options="['pending', 'accepted']"
                  :disabled="disabled"
                  @change="update({ updates: { status: $event } })"
                />
              </div>
              <!-- <div class="field">
                <div class="control">
                  <sqr-button
                    label="sheet_recompute"
                    @click="recompute()"
                    is-fullwidth
                    size="medium"
                    :disabled="disabled"
                  />
                </div>
              </div> -->
              <div class="field">
                <div class="control">
                  <sqr-button
                    icon="magic"
                    label="sheet_generate"
                    tooltip="sheet_generate_tooltip"
                    @click="generate"
                    :is-loading="generating"
                    is-fullwidth
                    size="medium"
                    :disabled="disabled"
                  />
                </div>
              </div>
              <div class="field">
                <sqr-router-link
                  class="is-hidden-mobile"
                  icon="history"
                  label="sheet_versions"
                  to-name="sheet-versions"
                  :to-id="id"
                  is-fullwidth
                  size="medium"
                />
              </div>
              <update-info class="mt-3" v-if="sheet" :value="sheet" />
              <update-progress :loading="saving" />
              <sqr-error-note :error="saveError" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import { fromPairs, clone, assocPath, dissocPath, pick } from 'ramda';

import nanourl from '@/utils/nanourl';

import firebase from 'firebase/app';
const FieldValue = firebase.firestore.FieldValue;

import stamp from '@/utils/stamp';

import days from 'sqr-wotime-core/dates/days';
import entryDuration from 'sqr-wotime-core/sheet/day/entry/entryDuration';
import entryValid from 'sqr-wotime-core/sheet/day/entry/entryValid';
import dayCompute from 'sqr-wotime-core/sheet/day/dayCompute';
import sheetCompute from 'sqr-wotime-core/sheet/sheetCompute';
import generate from 'sqr-wotime-core/entries/generate';

import account from './account';

import SqrProgress from '@/sqrd/SqrProgress';
import SqrError from '@/sqrd/SqrError';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrButton from '@/sqrd/SqrButton';
import SqrRouterLink from '@/sqrd/SqrRouterLink';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
import UpdateProgress from '@/components/UpdateProgress';

// import SqrInputText from '@/sqrd/SqrInputText';
import WeekSettings from '@/components/WeekSettings';
import WeekDays from '@/components/WeekDays';
import WeekTotal from '@/components/WeekTotal';
import WeekNotices from '@/components/WeekNotices';
// import SqrRouterLink from '../sqrd/SqrRouterLink';
import StatusMessage from '@/components/StatusMessage';
import StatusButtons from '@/components/StatusButtons';
import UpdateInfo from '@/components/UpdateInfo';

import week from '@/filters/week';

export default {
  name: 'AccountSheet',
  mixins: [account],
  filters: {
    week
  },
  components: {
    SqrProgress,
    SqrError,
    SqrErrorNote,
    SqrButton,
    SqrRouterLink,
    CompanyName,
    AccountName,
    UpdateProgress,
    WeekSettings,
    WeekDays,
    WeekNotices,
    WeekTotal,
    StatusMessage,
    StatusButtons,
    UpdateInfo
  },
  props: {
    sid: String
  },
  computed: {
    ...mapGetters('auth', ['uid']),
    ...mapGetters('perms', ['isManager', 'isManagerRW', 'isEmployeeRW']),

    ...mapState('sheet', { sheet: 'doc' }),
    ...mapState('sheet', ['id', 'path', 'exists', 'loading', 'loadError']),
    year() {
      return this.sid?.substring(0, 4);
    },
    week() {
      return this.sid?.split('-')?.[1];
    },
    disabled() {
      const canEdit =
        this.isManagerRW || (this.isEmployeeRW && this.aid === this.uid);
      return !canEdit || this.sheet?.status !== 'pending';
    },
    sheetNext() {
      // if (!this.sheet) return null;
      const next = DateTime.fromISO(this.sid).plus({ week: 1 });
      if (next > DateTime.local()) return null;
      const id = next.toISOWeekDate().replace(/-1$/, '');
      return {
        startDate: id,
        params: { cid: this.cid, aid: this.aid, sid: id }
      };
    },
    sheetPrevious() {
      const sid = DateTime.fromISO(this.sid)
        .minus({ week: 1 })
        .toISOWeekDate()
        .replace(/-1$/, '');
      return {
        startDate: sid,
        params: { cid: this.cid, aid: this.aid, sid }
      };
    }
  },
  data() {
    return {
      generating: false,
      saving: false,
      saveError: null
    };
  },
  watch: {
    sid(sid) {
      const path = `companies/${this.cid}/accounts/${this.aid}/sheets/${sid}`;
      this.sub({ path });
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeUnload);
    // window.onbeforeunload = (e) => {
    //   e.preventDefault();
    //   e.returnValue = 'Hello?';
    //   return true
    // }
  },
  mounted() {
    const path = `companies/${this.cid}/accounts/${this.aid}/sheets/${this.sid}`;
    this.sub({ path });
  },
  beforeDestroy() {
    // window.removeEventListener('beforeunload', this.beforeUnload);
  },
  async beforeRouteLeave(from, to, next) {
    if (this.saving) {
      alert(this.$t('sheet_saving'));
    } else {
      next();
    }
  },
  methods: {
    ...mapActions('sheet', ['sub', 'init']),
    ...mapMutations('sheets', { sheetsSet: 'fieldSet' }),
    async create() {
      try {
        this.saving = true;
        this.saveError = null;
        const week = DateTime.fromISO(this.sid);
        const startDate = week.startOf('week').toISODate();
        const endDate = week.endOf('week').toISODate();

        const weekSettings = this.account?.weekSettings;
        const settings = pick(['due', 'max', 'conv'], weekSettings);
        if (weekSettings?.weekType === 'alternate') {
          settings.due =
            week.weekNumber % 2 ? weekSettings.dueOdd : weekSettings.dueEven;
        }

        const sheet = {
          archived: false,
          status: 'pending',
          startDate,
          endDate,
          settings,
          timeEntriesById: {},
          totals: [],
          totalsByDay: fromPairs(
            days(startDate, endDate).map(day => [day, dayCompute([])])
          )
        };
        const path = `companies/${this.cid}/accounts/${this.aid}/sheets/${this.sid}`;
        await this.$db()
          .doc(path)
          .set(sheet);
        // reset the totalPages on sheets
        this.sheetsSet({ field: 'totalPages', value: undefined });
      } catch (error) {
        this.saveError = error;
      } finally {
        this.saving = false;
      }
    },
    async update({ updates }) {
      try {
        this.saving = true;
        this.saveError = null;
        const path = this.sheet?.path;
        const payload = {
          ...updates,
          updated: stamp()
        };
        await this.$db()
          .doc(path)
          .update(payload);
      } catch (error) {
        this.saveError = error;
      } finally {
        this.saving = false;
      }
    },
    entryAdd({ date, model }) {
      const id = `${new Date().getTime()}-${nanourl(5)}`;
      const base = JSON.parse(JSON.stringify(model));
      const value = { ...base, date };

      const cloned = clone(this.sheet);
      const sheet = assocPath(['timeEntriesById', id], value, cloned);
      const updates = { [`timeEntriesById.${id}`]: value };

      const result = this.entryCompute({ sheet, updates, date, id });
      this.update(result);
    },
    entryChange({ date, id, field, value }) {
      const entry = this.sheet.timeEntriesById[id];
      const entry2 = { ...entry, [field]: value };

      const cloned = clone(this.sheet);
      const sheet = assocPath(['timeEntriesById', id], entry2, cloned);
      const updates = { [`timeEntriesById.${id}`]: entry2 };

      const result = this.entryCompute({ sheet, updates, date, id });
      return this.update(result);
    },
    entryRemove({ date, id }) {
      const sheet = dissocPath(['timeEntriesById', id], clone(this.sheet));
      const updates = { [`timeEntriesById.${id}`]: FieldValue.delete() };

      const result = this.dayCompute({ sheet, updates, date });
      this.update(result);
    },
    entryCompute({ sheet, updates, date, id }) {
      const entry = sheet.timeEntriesById[id];
      const entry2 = { ...entry };
      const valid = entryValid(entry2);
      entry2['valid'] = valid;
      if (valid) {
        const duration = entryDuration(entry2);
        if (duration) {
          entry2['duration'] = duration;
        }
      } else {
        entry2['duration'] = 'PT0S';
      }

      const sheet2 = assocPath(['timeEntriesById', id], entry2, sheet);
      const updates2 = { ...updates, [`timeEntriesById.${id}`]: entry2 };
      return this.dayCompute({ sheet: sheet2, updates: updates2, date });
    },
    dayCompute({ sheet, updates, date }) {
      const entries = Object.values(sheet.timeEntriesById).filter(
        entry => entry?.date === date
      );
      const totals = dayCompute(entries);

      const sheet2 = assocPath(['totalsByDay', date], totals, sheet);
      const updates2 = { ...updates, [`totalsByDay.${date}`]: totals };
      return this.sheetCompute({ sheet: sheet2, updates: updates2 });
    },
    fieldSetAndCompute({ field, value }) {
      const sheet = assocPath([field], value, this.sheet);
      const updates = { [field]: value };
      const result = this.sheetCompute({ sheet, updates });
      return this.update(result);
    },
    recompute() {
      const result = this.sheetCompute({ sheet: this.sheet, updates: {} });
      return this.update(result);
    },
    sheetCompute({ sheet, updates }) {
      const extensions = this.company?.extensions ?? [];
      const { totals, notices, noticesCount, summaries } = sheetCompute(
        sheet,
        extensions
      );
      const sheet2 = { ...sheet, totals, notices, noticesCount, summaries };
      const updates2 = { ...updates, totals, notices, noticesCount, summaries };
      return { sheet: sheet2, updates: updates2 };
    },
    async generate() {
      try {
        this.generating = true;
        this.generateError = null;
        const sheet = clone(this.sheet);
        const week = DateTime.fromISO(sheet.startDate)
          .toISOWeekDate()
          .replace(/-\d$/, '');
        const eventsProm = this.$db()
          .collection(`companies/${this.cid}/accounts/${this.aid}/events`)
          .where('weeks', 'array-contains', week)
          // .where('status', 'in', ['pending', 'validated'])
          .get();
        const passagesProm = this.$db()
          .doc(
            `companies/${this.cid}/accounts/${this.aid}/passages/${this.sid}`
          )
          .get();
        const models = this.company.entryModels;
        const model = models.find(({ id }) => id === 'work');
        const extensions = this.company.extensions;
        const [eventsSnap, passagesSnap] = await Promise.all([
          eventsProm,
          passagesProm
        ]);
        const events = eventsSnap.docs.map(snap => ({
          ...snap.data(),
          id: snap.id
        }));
        const passages = passagesSnap.exists
          ? Object.values(passagesSnap.data().passagesById ?? {})
          : [];
        const result = generate(sheet, passages, events, model, extensions);
        const updates = pick(
          [
            'timeEntriesById',
            'notices',
            'noticesCount',
            'totalsByDay',
            'summaries'
          ],
          result
        );
        const sheet2 = { ...sheet, ...updates };
        return this.update({ sheet: sheet2, updates });
      } catch (error) {
        this.generateError = error;
        return Promise.reject(error);
      } finally {
        this.generating = false;
      }
    },
    beforeUnload(e) {
      if (this.saving) {
        e.returnValue = 'unsaved changes';
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
