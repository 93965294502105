const { pickBy, isNil, fromPairs, flatten } = require('ramda');
// const flatMap = require('../utils/flatMap');
const groupBy = require('../utils/groupBy');

const generateFromEvents = require('./generateFromEvents');
const generateFromPassages = require('./generateFromPassages');

const entryDuration = require('../sheet/day/entry/entryDuration');
const entryValid = require('../sheet/day/entry/entryValid');
const dayCompute = require('../sheet/day/dayCompute');

const sheetCompute = require('../sheet/sheetCompute');

const generate = function (fromSheet, passages, events, model, extensions) {
  const entriesFromSheet = Object.entries(fromSheet.timeEntriesById).map(([id, entry]) => ({ ...entry, id }));
  const entriesManualFromSheet = entriesFromSheet.filter(e => !e.generated);
  const entriesFromEvents = generateFromEvents(events, fromSheet.startDate);
  const entriesFromPassages = generateFromPassages(passages, model);
  const entries = [].concat(entriesManualFromSheet).concat(entriesFromEvents).concat(entriesFromPassages);

  // recompute each entries
  const computed = entries
    .map(entry => {
      const clone = { ...entry };
      clone.valid = entryValid(entry);
      if (clone.valid) {
        clone.duration = entryDuration(entry);
      } else {
        clone.duration = 'PT0S';
      }
      return clone;
    })
    .map(pickBy(v => !isNil(v)));

  const entriesOriginalByDay = groupBy(entriesFromSheet, 'date');
  const entriesByDay = groupBy(computed, 'date');
  const entriesDayPairs = Object.entries(entriesByDay).map(([day, entries]) => {
    const originalEntries = entriesOriginalByDay[day] || [];
    if (originalEntries.every(e => e.generated)) {
      return [day, entries];
    } else {
      return [day, originalEntries];
    }
  });

  // build the totals
  const totalsByDay = fromPairs(
    entriesDayPairs.map(([day, entries]) => {
      return [day, dayCompute(entries)];
    })
  );

  const entriesNew = flatten(entriesDayPairs.map(pairs => pairs[1]));
  const timeEntriesById = fromPairs(entriesNew.map(entry => [entry.id, entry]));

  // summarize the sheet
  const newSheet = {
    ...fromSheet,
    timeEntriesById,
    totalsByDay,
  };
  const newSheetComputed = { ...newSheet, ...sheetCompute(newSheet, extensions) };
  return newSheetComputed;
};

module.exports = generate;
