const flatMap = require('../../utils/flatMap');

module.exports = function (sheet, previous) {
  let entries = flatMap(sheet.days, d => d.entries);

  if (entries.some(e => !e.valid)) {
    let notice = {
      id: 'invalid',
      color: 'danger',
      icon: 'exclamation-triangle',
      message: 'some entries in the work week are invalid',
      message18: {
        en: 'some entries in the work week are invalid',
        fr: 'certaines entrées dans la semaine de travail sont invalides',
        de: ''
      }
    };
    return {
      totals: previous.totals,
      notices: [...previous.notices, notice],
      summaries: previous.summaries
    };
  } else {
    return previous;
  }
};