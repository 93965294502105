module.exports = function (sheet, previous) {
  if (!sheet || !sheet.settings) {
    return {
      stop: true,
      totals: [],
      notices: [
        {
          id: 'settings-missing',
          color: 'danger',
          icon: 'exclamation-triangle',
          message: 'sheet settings are missing',
          message18: {
            en: 'Work week settings are missing',
            fr: 'Les paramètres de la semaine de travail sont manquants',
            de: ''
          }
        }
      ],
      summaries: []
    };
  } else {
    return previous;
  }
};