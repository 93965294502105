<template>
  <div class="card" v-if="sheet">
    <div class="is-unselectable" @click="toggle">
      <div class="card-header-custom">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <fa :icon="toggleIcon" fixed-width />
            </div>
            <div class="level-item">
              <span class="card-header-custom-title">{{
                $t('week_settings')
              }}</span>
            </div>
          </div>
          <div class="level-right">
            <div
              class="level-item has-text-right tooltip"
              :data-tooltip="$t('sheet_settings_due')"
            >
              <p>{{ settings.due | durationTime }}</p>
              <span class="icon"><fa :icon="['fal', 'bullseye']" /></span>
            </div>
            <div
              class="level-item has-text-right tooltip"
              :data-tooltip="$t('sheet_settings_max')"
            >
              <span>{{ settings.max | durationTime }}</span>
              <span class="icon"><fa :icon="['fal', 'arrow-to-top']" /></span>
            </div>
            <div
              class="level-item has-text-right tooltip"
              :data-tooltip="$t('sheet_settings_conv')"
            >
              <span>{{ settings.conv | durationTime }}</span>
              <span class="icon"><fa :icon="['fal', 'exchange-alt']" /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content" v-show="toggled">
      <div class="columns">
        <div class="column">
          <div class="field">
            <input-duration-time
              icon="bullseye"
              label="sheet_settings_due"
              :value="settings.due"
              @change="settingsChange({ field: 'due', value: $event })"
              guess="hh"
              :disabled="disabled"
              :invalid="dueCap"
            />
            <div class="help is-danger" v-if="dueCap">
              {{ $t('sheet_settings_due_cap') }}
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <input-duration-time
              icon="arrow-to-top"
              label="sheet_settings_max"
              :value="settings.max"
              @change="settingsChange({ field: 'max', value: $event })"
              guess="hh"
              :disabled="disabled"
              :invalid="maxCap"
            />
            <div class="help is-danger" v-if="maxCap">
              {{ $t('sheet_settings_max_cap') }}
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <input-duration-time
              icon="exchange-alt"
              label="sheet_settings_conv"
              :value="settings.conv"
              @change="settingsChange({ field: 'conv', value: $event })"
              guess="hh"
              :disabled="disabled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Duration } from 'luxon';
import toggle from './toggle';
import InputDurationTime from './InputDurationTime';
import durationTime from '../filters/durationTime';

export default {
  name: 'WeekParams',
  mixins: [toggle],
  filters: { durationTime },
  components: { InputDurationTime },
  props: {
    sheet: Object,
    disabled: Boolean,
  },
  computed: {
    settings() {
      return this.sheet?.settings;
    },
    dueCap() {
      const cap = Duration.fromISO('PT45H');
      const due = Duration.fromISO(this.settings.due);
      return due >= cap;
    },
    maxCap() {
      const cap = Duration.fromISO('PT45H');
      const due = Duration.fromISO(this.settings.max);
      return due > cap;
    },
  },
  methods: {
    settingsChange({ field, value }) {
      const settings = { ...this.settings } ?? {};
      this.$set(settings, field, value);
      this.$emit('change', settings);
    },
  },
};
</script>

<style scoped>
.card-header-custom {
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
  padding: 0.75rem;
}

.card-header-custom-title {
  font-weight: 700;
}
</style>
