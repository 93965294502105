const Duration = require('luxon').Duration;

// const flatMap = require('../../utils/flatMap');

const computeSumsByIcon = require('../../common/computeSumsByIcon');
const computeSumsByOp = require('../../common/computeSumsByOp');

const summaryCreate = require('../../common/summaryCreate');

function convertTotal (total, conv) {
  if (!total) {
    return Duration.fromMillis(0);
  } else if (!total.duration && !total.day) {
    return Duration.fromMillis(0);
  } else if (!total.day) {
    return Duration.fromISO(total.duration);
  } else if (!total.duration) {
    return times(conv, total.day);
  } else {
    return Duration.fromISO(total.duration).plus(times(conv, total.day));
  }
}

function findOp (totals, op) {
  let total = totals.find(t => t.op === op);
  if (!total) {
    return {op, duration: Duration.fromMillis(0).toISO(), day: 0};
  } else {
    return total;
  }
}

function times (duration, factor) {
  return Duration.fromMillis(duration.as('milliseconds') * factor);
}

module.exports = function (sheet, previous) {
  // let entries = flatMap(sheet.days, d => d.entries);
  const entries = Object.values(sheet.timeEntriesById);

  let totalsByIcon = computeSumsByIcon(entries);

  let simpleEntries = entries.map(e => ({
    op: e.op,
    input: e.input,
    duration: e.duration,
    day: e.day
  }));

  let totals = computeSumsByOp(simpleEntries);

  let due = Duration.fromISO(sheet.settings.due);
  let max = Duration.fromISO(sheet.settings.max);
  let conv = Duration.fromISO(sheet.settings.conv);

  let work = findOp(totals, 'work');
  let workConvDuration = convertTotal(work, conv);

  let pause = findOp(totals, 'pause');
  let pauseConvDuration = convertTotal(pause, conv);

  let workActualDuration = workConvDuration.minus(pauseConvDuration).normalize();

  let sick = findOp(totals, 'sick');
  let sickConvDuration = convertTotal(sick, conv);

  let sickChild = findOp(totals, 'sick-child');
  let sickChildConvDuration = convertTotal(sickChild, conv);

  let service = findOp(totals, 'service');
  let serviceConvDuration = convertTotal(service, conv);

  let leave = findOp(totals, 'leave');
  let leaveConvDuration = convertTotal(leave, conv);

  let maternityLeave = findOp(totals, 'maternity-leave');
  let maternityLeaveConvDuration = convertTotal(maternityLeave, conv);

  let vacation = findOp(totals, 'vacation');
  let vacationConvDuration = convertTotal(vacation, conv);

  let holiday = findOp(totals, 'holiday');
  let holidayConvDuration = convertTotal(holiday, conv);

  let dueActualDuration = due
    .minus(sickConvDuration)
    .minus(sickChildConvDuration)
    .minus(serviceConvDuration)
    .minus(leaveConvDuration)
    .minus(maternityLeaveConvDuration)
    .minus(vacationConvDuration)
    .minus(holidayConvDuration)
    .normalize();

  let recovery = Duration.fromMillis(0);
  let overtime = Duration.fromMillis(0);
  let extratime = Duration.fromMillis(0);

  let diff = workActualDuration.minus(dueActualDuration).normalize();

  if (diff.as('minutes') > 0) {
    let maxDiff = workActualDuration.minus(max).normalize();
    if (maxDiff.as('seconds') > 0) {
      extratime = maxDiff;
      diff = diff.minus(maxDiff).normalize();
    }
    overtime = diff;
    workActualDuration = workActualDuration.minus(overtime).minus(extratime).normalize();
  } else if (diff.as('minutes') < 0) {
    recovery = diff;
  }

  let totalWorked = workActualDuration
    .plus(overtime)
    .plus(extratime)
    .minus(recovery)
    .normalize();

  // let zero = Duration.fromISO('PT0S');

  let summaries = [
    summaryCreate('max', max),
    summaryCreate('due', dueActualDuration),
    summaryCreate('normal', workActualDuration),
    summaryCreate('recovery', recovery),
    summaryCreate('over-time', overtime),
    summaryCreate('extra-time', extratime),
    summaryCreate('total-worked', totalWorked),
    summaryCreate('service', service.duration, service.day),
    summaryCreate('leave', leave.duration, leave.day),
    summaryCreate('maternity-leave', maternityLeave.duration, maternityLeave.day),

    summaryCreate('sick', sick.duration, sick.day),
    summaryCreate('sick-child', sickChild.duration, sickChild.day),
    summaryCreate('vacation', vacation.duration, vacation.day),
    summaryCreate('holiday', holiday.duration, holiday.day)
  ].filter(s => s.force || s.duration !== 'PT0S' || !!s.day);

  return {
    totals: totalsByIcon,
    summaries: summaries,
    notices: previous.notices
  };
};