const DateTime = require('luxon').DateTime;
const Interval = require('luxon').Interval;
const flatMap = require('../../utils/flatMap');

module.exports = function (sheet, previous) {
  let entries = flatMap(sheet.days, d => d.entries.filter(e => e.input === 'from-until' && e.valid));
  let intervals = entries.map(e => {
    let start = DateTime.fromISO(e.date + 'T' + e.from);
    let end = DateTime.fromISO(e.date + 'T' + e.until);
    if (end < start) {
      end = end.plus({days: 1});
    }
    return Interval.fromDateTimes(start, end);
  });

  let overlaps = intervals.filter(a => intervals.some(b => a !== b && a.overlaps(b)));

  let days = Array.from(new Set(overlaps.map(i => i.start.toISODate())));

  if (overlaps.length) {
    let notice = {
      id: 'overlaps',
      color: 'danger',
      icon: 'exclamation-triangle',
      message: 'notice_overlaps',
      message18: {
        en: `${overlaps.length} entries overlap (${days})`,
        fr: `${overlaps.length} entrées sont superposées (${days})`,
        de: ''
      }
    };
    return {
      totals: previous.totals,
      notices: [...previous.notices, notice],
      summaries: previous.summaries
    };
  } else {
    return previous;
  }
};