const DateTime = require('luxon').DateTime;
const Duration = require('luxon').Duration;

module.exports = function (entry) {
  if (!entry) return undefined;
  if (entry.input === 'from-until') {
    let from = DateTime.fromISO(entry.from);
    let until = DateTime.fromISO(entry.until);
    let minDiff = until.minute - from.minute;
    let carry = 0;
    if (minDiff < 0) {
      carry = 1;
      minDiff = minDiff + 60;
    }
    let hourDiff = until.hour - from.hour - carry;
    let duration = Duration.fromObject({
      hours: hourDiff < 0 ? hourDiff + 24 : hourDiff,
      minutes: minDiff
    });
    return duration.toISO();
  } else {
    return undefined;
  }
};