const {Duration} = require('luxon');

module.exports = function(sheet, previous) {
  const entries = Object.values(sheet.timeEntriesById);
  if (entries.some(e => e.op === 'pause' && e.input === 'duration' && Duration.fromISO(e.duration).minutes >= 30)) {
    return {
      ...previous,
      notices: [
        ...previous.notices,
        {
          id: 'pause30',
          color: 'warning',
          icon: 'exclamation-triangle',
          message: 'pause',
          message18: {
            en:
              'Breaks of more than 30 minutes must be documented by a start time and an end time. For example by noting two periods of work.',
            fr:
              'Les pauses de plus de 30 minutes doivent être documentée par une heure de début et une heure de fin. Par exemple en notant deux périodes de travail.',
            de:
              'Pausen von mehr als 30 Minuten müssen durch eine Start- und eine Endzeit dokumentiert werden. Zum Beispiel durch Notieren von zwei Arbeitsperioden.'
          }
        }
      ]
    };
  } else {
    return previous;
  }
};
