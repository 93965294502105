module.exports = function (sheet, previous) {
  let summary = previous.summaries.find(s => s.name === 'due');
  if (summary && summary.negative) {
    let notice = {
      id: 'due',
      color: 'danger',
      icon: 'exclamation-triangle',
      message: 'work time due is negative (to much vacation or leave)',
      message18: {
        en: 'work time due is negative (to much vacation or leave)',
        fr: 'le temps de travail dû est négatif (trop de vacance ou d\'absence)',
        de: ''
      }
    };
    return {
      totals: previous.totals,
      notices: [...previous.notices, notice],
      summaries: previous.summaries
    };
  } else {
    return previous;
  }
};