const check = require('./tasks/check');
const base = require('./tasks/base');
const invalid = require('./tasks/invalid');
const checkDue = require('./tasks/checkDue');
const overlaps = require('./tasks/overlaps');
const ltr15 = require('./tasks/ltr15');
const ltr15wc = require('./tasks/ltr15Webcor');
const pause30 = require('./tasks/pause30');
const factor = require('./tasks/factor');

module.exports = function (sheet, extensions = []) {
  let initial = { summaries: [], notices: [], totals: [] };
  try {
    let tasks = [check, base, invalid, checkDue];

    if (extensions.indexOf('overlaps') >= 0) {
      tasks.push(overlaps);
    }

    if (extensions.indexOf('ltr15') >= 0 || extensions.indexOf('ltr17') >= 0) {
      tasks.push(ltr15);
    }

    if (extensions.indexOf('ltr15-wc') >= 0) {
      tasks.push(ltr15wc);
    }

    if (extensions.indexOf('pause30') >= 0) {
      tasks.push(pause30);
    }

    tasks.push(factor);

    let results = JSON.parse(JSON.stringify(initial));
    tasks.forEach(task => {
      if (!results.stop) {
        results = task(sheet, results);
      }
    });

    let noticesCount = results.notices.reduce((map, notice) => {
      if (!map[notice.color]) map[notice.color] = 0;
      map[notice.color] += 1;
      return map;
    }, {});
    results.noticesCount = noticesCount;
    return results;
  } catch (error) {
    // throw error;
    return require('./tasks/error')(sheet, initial, error);
  }
};
