const DateTime = require('luxon').DateTime;
const IANAZone = require('luxon').IANAZone;
const groupPassages = require('../passages/groupPassages');

const zone = IANAZone.create('Europe/Zurich');
const fromOpts = { zone };
const formatOpts = { hour: '2-digit', minute: '2-digit', hour12: false };
module.exports = function (passages, model) {
  if (!passages || passages.length === 0) return [];

  let grouped = groupPassages(passages);

  let entries = grouped.groups
    .map(group => {
      let entry = JSON.parse(JSON.stringify(model));
      let inOrOut = group.in || group.out;
      if (!inOrOut) return null;
      entry.id = inOrOut.id;
      entry.date = DateTime.fromISO(inOrOut.date, fromOpts).toISODate();
      entry.from = group.in ? DateTime.fromISO(inOrOut.date + 'T' + group.in.time, fromOpts).toLocaleString(formatOpts) : '';
      entry.until = group.out ? DateTime.fromISO(inOrOut.date + 'T' + group.out.time, fromOpts).toLocaleString(formatOpts) : '';
      // console.log(entry.date, group.in.time, entry.from, group.out.time, entry.until);
      entry.generated = true;
      return entry;
    })
    .filter(v => v);
  return entries;
};
